<template>
	<div class="menu-tbf">
		<div class="content-menu">
			<div class="top-menu">
				<button class="close-menu" @click="$emit('close_menu')"><img src="/build/images/close.svg"></button>
			</div>
			<div class="list-menu">
				<div class="item-menu">
					<div class="name"><router-link :to="{name: 'dashboard' }">{{ $t('navbar.dashboard') }}</router-link></div>
					<div class="icon"><img src="/build/images/arrow-link-menu.svg"></div>
				</div>
				<div class="item-menu" v-for="(ml,index) in links" v-bind:key="index">
					<div class="name"><a :href="ml['link']">{{ ml['name'] }}</a></div>
					<div class="icon"><img src="/build/images/arrow-link-menu.svg"></div>
				</div>
			</div>

			<div class="bottom-menu">
				<div class="text-contact">
					<div class="label">Contactează-ne</div>
					<div class="mail"><a href="mailto:digital@tbf.ro">digital@tbf.ro</a></div>
				</div>
				<div class="icon"><img src="/build/images/contact-mail.svg"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				links: {}
			};
		},
		mounted(){
			this.getLinks();
		},
		methods:{
			getLinks(){
				axios.get("https://backend.tbf.ro/api/digital-links").then(res => {
					this.links = res.data.main_menu;
				})
			}
		}
	}
</script>